/* eslint-disable react/display-name */
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'src/containers/Link';
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Grid } from 'src/components/molecules/Grid';
import { Card } from 'src/components/atoms/Card';
import { FluidImg } from 'src/embeds/image/image-dato';
import { useStaticQuery, graphql } from 'gatsby';

function createColumns(n) {
  return Array(n).fill('1fr').join(' ');
}

const CalloutHeader = styled.h3`
  ${({ customTextColor }) =>
    customTextColor &&
    css`
      color: ${customTextColor};
    `}
`;

const cardStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-break: keep-all;
  hyphens: none;
  white-space: pre-wrap;
`;

const query = graphql`
  query ResourceQuery {
    allDatoCmsResource(
      filter: {
        category: { id: { eq: "DatoCmsResourceCategory-90402100-en" } }
        meta: { status: { eq: "published" } }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 9
    ) {
      nodes {
        id
        title
        description
        displayCategory
        url
        meta {
          firstPublishedAt
        }
        image {
          width
          height
          url
        }
        category {
          title
          id
        }
      }
    }
  }
`;

const preHeadingStyles = css`
  font-size: ${({ theme }) => theme.smallFontSize};
  margin-bottom: 0.7rem;
`;

export default function Drawer({
  heading,
  content,
  contentNode,
  callouts,
  ctas,
  customData,
  backgroundImage,
}) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  const drawerCustomData = JSON.parse(customData);
  const data = useStaticQuery(query);
  /** .toDateString to remove time from today's date */
  const today = new Date(new Date().toDateString());

  const events = (data?.allDatoCmsResource?.nodes || []).filter((el) => {
    const eventDateObj = new Date(el.meta.firstPublishedAt);
    const eventDateDifference = eventDateObj - today;
    /** Filter dates in the past */
    return eventDateDifference < 0;
  });

  return (
    <Section
      {...drawerCustomData?.flags}
      backgroundImage={
        !!backgroundImage && (
          <FluidImg pictureStyle={{ objectFit: 'cover' }} maxWidth={null} data={backgroundImage} />
        )
      }
    >
      <SectionHeader>
        {heading && <h2>{heading}</h2>}
        {content && <div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }} />}
      </SectionHeader>
      {events.length > 0 && (
        <Grid columns={createColumns(drawerCustomData?.columnCount ?? callouts.length)}>
          {events.map((event) => {
            const { description, id, image, title, url, displayCategory } = event;
            return (
              <Card key={id} css={cardStyles} noPadding>
                <div>
                  {image && <FluidImg data={image} width={image.width || image.height} />}
                  <div
                    css={css`
                      padding: 1.5rem 2rem;
                    `}
                  >
                    {displayCategory && <div css={preHeadingStyles}>{displayCategory}</div>}
                    <CalloutHeader>{title}</CalloutHeader>
                    <div style={{ marginBottom: '1rem' }}>{description}</div>
                  </div>
                </div>
                <div
                  css={css`
                    padding: 2rem;
                  `}
                >
                  <Link to={url}>View Webinar</Link>
                </div>
              </Card>
            );
          })}
        </Grid>
      )}
    </Section>
  );
}
